<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between test2">
        <div class="test">
          <!-- all page -->
          <button @click="gotoAll()">
            <div class="me-2 box_content">
              <p class="mb-0">
                {{
                  this.$store.state.draft.amount +
                    this.$store.state.notpaid.amount +
                    this.$store.state.paid.amount +
                    this.$store.state.makeSend.amount +
                    this.$store.state.send.amount +
                    this.$store.state.expire.amount +
                    this.$store.state.cancel.amount
                }}
              </p>
              <p class="mb-0">ทั้งหมด</p>
            </div>
          </button>
          <!-- draft page -->
          <button @click="gotoDraft()">
            <div class="me-2 box_content ">
              <p class="mb-0">{{ this.$store.state.draft.amount }}</p>
              <p class="mb-0">ร่าง</p>
            </div>
          </button>
          <!-- not paid -->
          <button @click="gotoNotPaid()">
            <div class="me-2 box_content ">
              <p class="mb-0">{{ this.$store.state.notpaid.amount }}</p>
              <p class="mb-0">ยังไม่จ่าย</p>
            </div>
          </button>
          <!-- paid -->
          <button @click="gotoPaid()">
            <div class="me-2 box_content ">
              <p class="mb-0">{{ this.$store.state.paid.amount }}</p>
              <p class="mb-0">โอนแล้ว</p>
            </div>
          </button>
          <!-- MakeSend -->
          <button @click="gotoMakeSend()">
            <div class="me-2 box_content ">
              <p class="mb-0">{{ this.$store.state.makeSend.amount }}</p>
              <p class="mb-0">เตรียมส่ง</p>
            </div>
          </button>
          <!-- Send -->
          <button @click="gotoSend()">
            <div class="me-2 box_content">
              <p class="mb-0">{{ this.$store.state.send.amount }}</p>
              <p class="mb-0">ส่งแล้ว</p>
            </div>
          </button>
          <!-- Expire -->
          <button @click="gotoExpire()">
            <div class="me-2 box_content active">
              <p class="mb-0">{{ this.$store.state.expire.amount }}</p>
              <p class="mb-0">หมดอายุ</p>
            </div>
          </button>
          <!-- Cancel -->
          <button @click="gotoCancel()">
            <div class="box_content">
              <p class="mb-0">{{ this.$store.state.cancel.amount }}</p>
              <p class="mb-0">ยกเลิก</p>
            </div>
          </button>
        </div>
        <div class="d-flex align-center">
          <v-dialog transition="dialog-top-transition" width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res">
                <v-icon class="me-2">
                  {{ icons.mdiCartMinus }}
                </v-icon>
                เปิดบิล
              </v-btn>
            </template>
            <template>
              <v-card>
                <v-card-text class="pb-0 pt-5 bg-header">
                  <div class="d-flex justify-space-between">
                    <p>เปิดบิลใหม่</p>
                    <span @click="switchOpenUser" v-if="dataOpenUser === false">เปิดบิลจากลูกค้ารายใหม่</span>
                    <span @click="switchOpenUser" v-if="dataOpenUser === true">เปิดบิลจากลูกค้าปัจจุบัน</span>
                  </div>
                </v-card-text>
                <!-- Content import -->
                <OpenBill v-if="dataOpenUser === false" />
                <OpenNewUser v-if="dataOpenUser === true" />
                <!-- <v-card-text>
                  <v-btn color="primary" @click="dialog.value = false" class="me-2">เปิดใช้งาน</v-btn>
                  <v-btn color="error" outlined @click="dialog.value = false">เรียนรู้เพิ่มเติม</v-btn>
                </v-card-text> -->
                <!-- <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions> -->
              </v-card>
            </template>
          </v-dialog>
        </div>
      </div>
      <v-row class="mt-5">
        <v-col md="4" sm="6" cols="12">
          <v-text-field
            v-model="search"
            :prepend-inner-icon="icons.mdiMagnify"
            label="ค้นหา"
            type="text"
            outlined
            dense
            hide-details
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-select
            :prepend-inner-icon="icons.mdiTruck"
            dense
            outlined
            hide-details
            label="เฉพาะขนส่ง"
            :items="transport"
          ></v-select>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-select
            :prepend-inner-icon="icons.mdiPrinterSearch"
            dense
            hide-details
            outlined
            label="กรองตามสถานะการพิมพ์"
            :items="statusFilter"
          ></v-select>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <div>
            <date-picker v-model="time3" range placeholder="เฉพาะวันที่" class="picker-res"></date-picker>
          </div>
        </v-col>
        <v-col md="8" sm="6" cols="12">
          <v-row>
            <v-col md="3" cols="12">
              <v-select dense hide-details outlined label="เปลี่ยนสถานะ" :items="changeStatus"></v-select>
            </v-col>
            <v-col md="3" cols="12">
              <v-select dense hide-details outlined label="พิมพ์" :items="print"></v-select>
            </v-col>
            <v-col md="3" cols="12">
              <v-select dense hide-details outlined label="ส่งสินค้า" :items="transportProduct"></v-select>
            </v-col>
            <v-col md="3" cols="12">
              <v-select dense hide-details outlined label="ดาวน์โหลด" :items="download"></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- ตารางสถานะต่าง ๆ -->
      <v-data-table
        v-model="selected"
        :search="search"
        :headers="headers"
        :items="desserts"
        item-key="name"
        show-select
        class="elevation-1"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCartMinus, mdiMagnify, mdiTruck, mdiPrinterSearch } from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import OpenBill from './OpenBill.vue'
import OpenNewUser from './OpenNewUser.vue'

export default {
  components: { DatePicker, OpenBill, OpenNewUser },
  setup() {
    const transport = ['ไม่มีข้อมูล']
    const statusFilter = ['ยังไม่พิมพ์ใบจ่าหน้า/ใบแพ็คของ', 'ยังไม่พิมพ์ใบเสร็จ/ใบกำกับ']
    const changeStatus = ['ร่าง', 'ยังไม่จ่าย', 'โอนแล้ว', 'เตรียมส่ง', 'ส่งแล้ว', 'ยกเลิก']
    const print = [
      'จ่าหน้าผู้รับ',
      'จ่าหน้าผู้รับ และผู้ส่ง',
      'ใบกำกับภาษี / ใบเสร็จรับเงิน',
      'ใบแพ๊กของ',
      'ใบแพ็กของพร้อมจ่าหน้า (ใหญ่)',
      'ใบแพ็กของพร้อมจ่าหน้า (เล็ก)',
      'ตั้งค่าหน้ากระดาษ',
    ]
    const transportProduct = [
      'นัดรับพรุ่งนี้เวลา 09:00 - 12:00 น.',
      'นัดรับพรุ่งนี้เวลา 12:00 - 15:00 น.',
      'นัดรับพรุ่งนี้เวลา 15:00 - 18:00 น.',
      'นัดรับวันจันทร์ ที่ 1 09:00 - 12:00 น.',
      'นัดรับวันจันทร์ ที่ 1 12:00 - 15:00 น.',
      'นัดรับวันจันทร์ ที่ 1 15:00 - 18:00 น.',
      'นัดรับวันอังคาร ที่ 2 09:00 - 12:00 น.',
      'นัดรับวันอังคาร ที่ 2 12:00 - 15:00 น.',
      'นัดรับวันอังคาร ที่ 2 15:00 - 18:00 น.',
      'นัดรับวันพุธ ที่ 3 09:00 - 12:00 น.',
      'นัดรับวันพุธ ที่ 3 12:00 - 15:00 น.',
      'นัดรับวันพุธ ที่ 3 15:00 - 18:00 น.',
      'นัดรับวันพฤหัสบดี ที่ 4 09:00 - 12:00 น.',
      'นัดรับวันพฤหัสบดี ที่ 4 12:00 - 15:00 น.',
      'นัดรับวันพฤหัสบดี ที่ 4 15:00 - 18:00 น.',
    ]
    const download = ['ดาวโหลด (CSV)']

    return {
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
      },
      transport,
      statusFilter,
      changeStatus,
      print,
      transportProduct,
      download,
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      search: '',
      dataOpenUser: false,
      selected: [],
      headers: [
        {
          text: 'เลขที่บิล',
          align: 'start',
          sortable: false,
          value: 'numberBill',
        },
        { text: 'วันที่เปิดบิล', value: 'timeBill' },
        { text: 'ผู้สั่งสินค้า', value: 'name' },
        { text: 'ยอดสุทธิ', value: 'total' },
        { text: 'สถานะ', value: 'status' },
      ],
      desserts: [
        {
          id: 'wyw1',
          name: 'น้องพิมเพลิน',
          numberBill: 101,
          timeBill: '28/7/2022 15:00',
          total: 100,
          status: 'ส่งแล้ว',
        },
        {
          id: 'wyw2',
          name: 'น้องมารวย',
          numberBill: 102,
          timeBill: '30/7/2022 18:00',
          total: 500,
          status: 'ร่าง',
        },
        {
          id: 'wyw2',
          name: 'น้องมารวย',
          numberBill: 102,
          timeBill: '30/7/2022 18:00',
          total: 500,
          status: 'ร่าง',
        },
        {
          id: 'wyw1',
          name: 'น้องพิมเพลิน',
          numberBill: 101,
          timeBill: '28/7/2022 15:00',
          total: 100,
          status: 'ส่งแล้ว',
        },
        {
          id: 'wyw2',
          name: 'น้องมารวย',
          numberBill: 102,
          timeBill: '30/7/2022 18:00',
          total: 500,
          status: 'ร่าง',
        },
        {
          id: 'wyw1',
          name: 'น้องพิมเพลิน',
          numberBill: 101,
          timeBill: '28/7/2022 15:00',
          total: 100,
          status: 'ส่งแล้ว',
        },
        {
          id: 'wyw1',
          name: 'น้องพิมเพลิน',
          numberBill: 101,
          timeBill: '28/7/2022 15:00',
          total: 100,
          status: 'ส่งแล้ว',
        },
        {
          id: 'wyw2',
          name: 'น้องมารวย',
          numberBill: 102,
          timeBill: '30/7/2022 18:00',
          total: 500,
          status: 'ร่าง',
        },
        {
          id: 'wyw1',
          name: 'น้องพิมเพลิน',
          numberBill: 101,
          timeBill: '28/7/2022 15:00',
          total: 100,
          status: 'ส่งแล้ว',
        },
        {
          id: 'wyw2',
          name: 'น้องมารวย',
          numberBill: 102,
          timeBill: '30/7/2022 18:00',
          total: 500,
          status: 'ร่าง',
        },
      ],
    }
  },
  methods: {
    gotoAll() {
      const allPage = this.$router.resolve({ path: '/pages/orders' })
      window.open(allPage.href)
    },
    gotoDraft() {
      const draftPage = this.$router.resolve({ path: '/pages/draft' })
      window.open(draftPage.href)
    },
    gotoNotPaid() {
      const notPaidtPage = this.$router.resolve({ path: '/pages/not-paid' })
      window.open(notPaidtPage.href)
    },
    gotoPaid() {
      const paidtPage = this.$router.resolve({ path: '/pages/paid' })
      window.open(paidtPage.href)
    },
    gotoMakeSend() {
      const makeSendPage = this.$router.resolve({ path: '/pages/make-send' })
      window.open(makeSendPage.href)
    },
    gotoSend() {
      const sendPage = this.$router.resolve({ path: '/pages/send' })
      window.open(sendPage.href)
    },
    gotoExpire() {
      const expirePage = this.$router.resolve({ path: '/pages/expire' })
      window.open(expirePage.href)
    },
    gotoCancel() {
      const cencelPage = this.$router.resolve({ path: '/pages/cancel' })
      window.open(cencelPage.href)
    },
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
  },
}
</script>

<style scoped>
.box_content {
  width: 5rem;
  height: 5rem;
  text-align: center;
  background-color: #f4f5f9;
  display: grid;
  align-items: center;
  padding: 10px 0;
  color: #8e8a98;
  font-weight: 600;
  font-size: 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.box_content.active {
  background-color: #8dbc89;
  color: #ffffff;
}
.bg-header {
  background-color: #8dbc89;
}
.bg-header p {
  color: #f4f5f9;
  font-weight: 600;
  font-size: 16px;
}
.bg-header span {
  color: #fff8ff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  text-decoration: underline;
}
.checkItem {
  width: 15px;
  height: 15px;
  margin-right: 1rem;
}
.picker-res {
  width: 100% !important;
}
@media screen and (max-width: 600px) {
  .test {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .test2 {
    display: grid !important;
  }
  .mg-res {
    margin-top: 1.5rem;
    transform: translateX(-40%);
    left: 50%;
  }
  /* .picker-res {
    width: 100% !important;
  } */
}
</style>
